<script>
export default {
  name: "TariffBuyTotalPrice",

  props: {
    tariff: {
      type: [Object, null],
    },

    priceInfo: {
      type: [Object, null],
    },
  },

  computed: {
    tariffPrice() {
      return Intl.NumberFormat("ru-RU").format(this.tariff?.user_price ?? 0);
    },

    totalPrice() {
      let total = this.tariff.user_price ?? 0;

      if (!this.priceInfo || this.priceInfo?.certificates.length === 0) {
        return Intl.NumberFormat("ru-RU").format(total);
      } else {
        this.priceInfo.certificates.forEach((item) => {
          total -= item.amount;
        });

        return total > 0 ? Intl.NumberFormat("ru-RU").format(total) : 0;
      }
    },
  },

  methods: {
    formatCertificatePrice(amount) {
      return `-${Intl.NumberFormat("ru-RU").format(amount ?? 0)} RC`;
    },
  },
};
</script>

<template>
  <div class="tariff-buy-total-price">
    <div class="tariff-buy-total-price__wrapper">
      <p class="tariff-buy-total-price__tariff-price">
        Стоимость тарифа <span>{{ tariffPrice }} RC</span>
      </p>

      <div class="tariff-buy-total-price__certificates">
        <p v-for="(certificate, index) in priceInfo.certificates" :key="index">
          Сертификат №{{ certificate.number }}
          <span>{{ formatCertificatePrice(certificate.amount) }}</span>
        </p>
      </div>
    </div>

    <span class="divider" />

    <section class="tariff-buy-total-price__total total">
      <p class="total__text">Итого</p>
      <p class="total__price">
        {{ totalPrice }} RC <span>{{ tariffPrice }}</span>
      </p>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.tariff-buy-total-price {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__tariff-price {
    @include text-2;
    color: $dark-primary;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__certificates {
    display: flex;
    flex-direction: column;
    gap: 12px;

    > p {
      @include text-2;
      color: $dark-primary;
      display: flex;
      justify-content: space-between;
      width: 100%;

      > span {
        color: $red;
      }
    }
  }

  .divider {
    margin: 24px 0;
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      @include body-1-bold-L;
      color: $dark-primary;
    }

    &__price {
      @include text-2-bold;

      > span {
        @include text-2;
        color: $dark-fifth;
        text-decoration-line: line-through;
      }
    }
  }
}
</style>
