<script>
import { mapActions, mapState } from "vuex";
import { CERTIFICATES_ERRORS } from "@/package/const/certificates-errors";
import MainInput from "@/components/helpers/MainInput.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

export default {
  name: "TariffBuyCertificates",

  components: { MainButtonIcon, MainButton, MainInput },

  props: {
    tariffId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      certificates: [
        {
          id: Date.now(),
          number: "",
          code: "",
          error: "",
          checked: false,
        },
      ],
    };
  },

  computed: {
    ...mapState({
      previewTariffPrice: (state) => state.tariff.previewTariffPrice,
    }),

    formattedCertificates() {
      let certificates = this.certificates.map((item) => item);

      certificates = certificates.filter(
        (certificate) =>
          certificate.number.length > 0 && certificate.code.length > 0
      );

      certificates = certificates.map((certificate) => {
        return {
          number: certificate.number,
          code: certificate.code,
        };
      });

      return certificates;
    },
  },

  methods: {
    ...mapActions({
      loadPreviewTariffPrice: "tariff/loadPreviewTariffPrice",
    }),

    addCertificate() {
      this.certificates.push({
        id: Date.now(),
        number: "",
        code: "",
        error: "",
        checked: false,
      });
    },

    deleteCertificate(id) {
      const index = this.certificates.findIndex(
        (certificate) => certificate.id === id
      );

      this.certificates.splice(index, 1);
      this.checkCertificate();
    },

    checkCertificate() {
      localStorage.setItem("certificates", JSON.stringify(this.certificates));

      this.loadPreviewTariffPrice({
        tariffId: this.tariffId,
        certificates: this.formattedCertificates,
      })
        .then((response) => {
          this.certificates?.forEach((item) => {
            item.error = "";
            item.checked = false;
          });

          response?.data?.certificates?.forEach((itemResponse) => {
            const certificate = this.certificates.find((item) => {
              return item.number === itemResponse.number;
            });

            if (certificate) {
              certificate.checked = true;
            }
          });
        })
        .catch((error) => {
          error.response?.data?.errors?.forEach((item) => {
            this.certificates[item.key].error =
              CERTIFICATES_ERRORS[item.message] ?? "Неизвестная ошибка";
            this.certificates[item.key].checked = false;
          });
        });
    },
  },

  mounted() {
    const certificates = localStorage.getItem("certificates");

    if (certificates && this.$route.params?.tariffId) {
      this.certificates = JSON.parse(localStorage.getItem("certificates"));

      this.checkCertificate();
    }
  },
};
</script>

<template>
  <div class="tariff-buy-certificates">
    <h2>Сертификаты</h2>

    <p>
      Если стоимость тарифа меньше вашего сертификата, остаток суммы на
      сертификате сгорает
    </p>

    <span class="divider" />

    <div class="tariff-buy-certificates__list">
      <div
        v-for="certificate in certificates"
        :key="certificate.id"
        class="tariff-buy-certificates__item item"
        :class="{ item_removable: certificates.length > 1 }"
      >
        <div class="item__inputs">
          <MainInput
            v-model="certificate.number"
            :label="'Номер сертификата'"
            :error="certificate.error.length > 0"
            @focusout="checkCertificate"
          >
            <template v-if="certificate.checked" #icon>
              <span class="icon-success" />
            </template>
          </MainInput>
          <MainInput
            v-model="certificate.code"
            :label="'Пин-код'"
            :mask="'####'"
            :error="certificate.error.length > 0"
            @focusout="checkCertificate"
          />
        </div>

        <MainButtonIcon
          v-if="certificates.length > 1"
          class="item__button-delete"
          @click="deleteCertificate(certificate.id)"
        >
          <span class="icon-close-bold" />
        </MainButtonIcon>

        <p class="item__error">{{ certificate.error }}</p>
      </div>
    </div>

    <MainButton
      class="tariff-buy-certificates__button-add"
      :title="'Добавить сертификат'"
      :color="'blue'"
      @click="addCertificate"
    />
  </div>
</template>

<style lang="scss" scoped>
.tariff-buy-certificates {
  > h2 {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 8px;
  }

  > p {
    @include text-2;
    color: $dark-fifth;
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;

    &_removable {
      grid-template-columns: 1fr 24px;
    }

    &__inputs {
      display: flex;
      gap: 16px;
    }

    .main-input {
      margin-bottom: 0;
      width: 100%;
    }

    &__error {
      @include caption-1;
      color: $red;
      padding: 0 4px;
      margin-top: 8px;
    }

    &__button-delete {
      margin-top: 40px;
      height: 24px;
      width: 24px;
    }

    .icon-close-bold {
      width: 24px;
      height: 24px;
      background-color: $dark-sixth;
    }

    .icon-success {
      position: absolute;
      top: 42px;
      right: 16px;
      width: 20px;
      height: 20px;
      background-color: $money-cv;
      z-index: 999;
    }
  }

  &__button-add {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .tariff-buy-certificates {
    .item {
      &__button-delete {
        margin-top: 36px;
      }
    }
  }
}

@media (max-width: 576px) {
  .tariff-buy-certificates {
    .item {
      &__inputs {
        flex-direction: column;
      }

      &__button-delete {
        margin-top: 34px;
      }
    }
  }
}
</style>
