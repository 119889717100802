<script>
import { TARIFFS_DESCRIPTIONS } from "@/package/const/tariffs-descriptions";
import { DISTRIBUTOR_TARIFFS } from "@/package/const/distributor-tariffs";

export default {
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      TARIFFS_DESCRIPTIONS,
      DISTRIBUTOR_TARIFFS,
    };
  },

  computed: {
    formattedPrice: function () {
      return this.tariff.user_price.toLocaleString("ru-RU");
    },
  },
};
</script>

<template>
  <div class="tariff-preview" :class="`tariff-preview_${tariff.code}`">
    <div class="tariff-preview__info">
      <h6 class="tariff-preview__info__description">Вы покупаете тариф</h6>

      <div class="tariff-preview__tariff tariff">
        <h2 class="tariff__name">
          <span class="tariff__icon" :class="`icon-${tariff.code}`"></span>
          {{ DISTRIBUTOR_TARIFFS[tariff.code].tariffName }}
        </h2>
        <div class="tariff__price">{{ formattedPrice }} RC</div>
      </div>
    </div>

    <div
      v-for="item in TARIFFS_DESCRIPTIONS[tariff.code].previewMainDescription"
      :key="item"
      class="tariff-preview__description"
    >
      <span class="tariff-preview__description__icon icon-check-mark"></span>

      <div class="tariff-preview__description__info" v-html="item"></div>
    </div>

    <div
      v-if="TARIFFS_DESCRIPTIONS[tariff.code].previewAdditionalDescription"
      class="tariff-preview__description__sub"
    >
      <div class="tariff-preview__description__sub__title">
        Возможность подключать предприятия
      </div>

      <div
        v-for="item in TARIFFS_DESCRIPTIONS[tariff.code]
          .previewAdditionalDescription"
        :key="item"
        class="tariff-preview__description__sub__item"
      >
        <span class="tariff-preview__description__sub__icon icon-dot"></span>

        <div v-html="item"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tariff-preview {
  border-radius: 8px;
  padding: $space-l $space-xl;
  background-color: $light-second;
  max-width: 600px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background-position: right bottom;
    background-size: contain;
    transform: scaleX(-1);
  }

  &_business_d {
    &:before {
      width: 280px;
      height: 280px;
      background-image: url("../../assets/images/tariff/business.png");

      @media (max-width: 900px) {
        width: 240px;
        height: 240px;
      }
    }
  }

  &_premium_d {
    &:before {
      width: 280px;
      height: 280px;
      background-image: url("../../assets/images/tariff/premium.png");
    }
  }

  &_vip_d {
    &:before {
      width: 400px;
      height: 400px;
      background-image: url("../../assets/images/tariff/vip.png");

      @media (max-width: 475px) {
        width: 280px;
        height: 280px;
      }
    }
  }

  &_vip_gold_d {
    &:before {
      width: 400px;
      height: 400px;
      background-image: url("../../assets/images/tariff/vip_gold.png");

      @media (max-width: 475px) {
        width: 280px;
        height: 280px;
      }
    }
  }

  &__info {
    margin: 0 0 $space-m;

    &__description {
      @include text-2;
      color: $dark-primary;
      margin: 0 0 $space-xs;
    }
  }

  .tariff {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &__name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      @include title-3;
    }

    &__icon {
      height: 24px;
      min-width: 24px;
      background-color: $dark-primary;
    }

    &__price {
      @include text-1;
      color: $dark-primary;
      white-space: nowrap;
      align-self: flex-end;
    }
  }

  &__description {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @include body-1;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__icon {
      margin: 5px 8px 0 0;
      height: 12px;
      min-width: 12px;
      background-color: $dark-primary;

      @media (max-width: 576px) {
        margin: 3px 8px 0 0;
      }
    }

    &__sub {
      background-color: $dark-fifth;
      border-radius: 8px;
      padding: 18px 20px;
      color: $light-primary;
      margin: $space-l 0 0;
      position: relative;

      &__title {
        @include body-1-bold;
        margin: 0 0 $space-m;
      }

      &__icon {
        height: 4px;
        min-width: 4px;
        background-color: $light-primary;
        margin-right: 8px;
        margin-top: 8px;

        @media (max-width: 576px) {
          margin-top: 6px;
        }
      }

      &__item {
        @include text-2;
        display: flex;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tariff-preview {
    padding: 24px;
  }
}

@media (max-width: 425px) {
  .tariff-preview {
    padding: 24px 16px;
  }
}
</style>
